/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
html, body, material-app, mat-sidenav-container {
  height: 100%;
  width: 100%;
  margin: 0;
}

mat-sidenav {
    width: 350px;
    padding:15px;
}
.mat-drawer {
    background-color: #023075;
    color:#fff
}
.main-content {
    padding:20px;
    height: calc(100% - 64px);
}
.toolbar-spacer {
    flex: 1 1 auto;
}
.mat-list-item-content {
  color: #000;
}
@import './app/theme';

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component that you are using.
@include angular-material-theme($app-theme);

body {
  margin: 0;
  height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  color: #292a1d;
}
a {
  text-decoration-color: mat-color($app-primary);
  color: mat-color($app-primary);
}
